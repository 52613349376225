
const OKTA_CLIENT_ID = '0oazpwilko47gv9Li2p7'
const ISSUER = 'https://amplify.okta.com/';
let base = window.location.origin
const REDIRECT_URI = `${base}/okta_callback`;

const OKTA_CONFIG = {
  oidc: {
    clientId: OKTA_CLIENT_ID,
    issuer: ISSUER,
    redirectUri: REDIRECT_URI,
    scopes: ['openid', 'profile', 'email'],
    pkce: true,
  },
};

export {
  OKTA_CONFIG,
  OKTA_CLIENT_ID
}
