
import {useOktaAuth} from "@okta/okta-react";
import {toRelativeUrl} from "@okta/okta-auth-js";


export default function OktaLogin() {

  const { oktaAuth } = useOktaAuth();

  const signInWithOkta = () => {
    const originalUri = toRelativeUrl(window.location.href, window.location.origin);
    oktaAuth.setOriginalUri(originalUri);
    oktaAuth.signInWithRedirect()
  }

  return (
    <>
        <div
            role="button"
            tabIndex={0}
            onClick={signInWithOkta}
            style={{
                cursor: 'pointer',
                width: '200px',
                margin: '10px auto 10px auto',
                textAlign: 'center',
                borderStyle: 'solid',
                borderWidth: '4px',
                borderColor: 'rgb(0,156,221)',
                padding: '5px',
                color: 'rgb(0,156,221)',
                fontWeight: 'bold',
                fontFamily: 'Aeonik Pro',
                fontSize: '17px'
            }}
        >
            <span>
                <img
                    style={{ maxHeight: '25px', width: '25px', margin: '0px 20px 0px 0px' }}
                    alt='Log in with Okta'
                    src="https://mathanex-media.learning.amplify.com/okta.png"
                />
            </span>
            Log in with Okta
        </div>
    </>
    );
}
