import React, { Component } from 'react';

import { Alert, Container } from 'library';

export default class OktaLanding extends Component {
    constructor(props) {
        super(props);

        this.state = {
            error: '',
        };
    }

    componentDidMount() {
        const { access, refresh } = this.props;

        localStorage.setItem('token', access);
        localStorage.setItem('refresh_token', refresh);
        localStorage.setItem('token_time', new Date());

        window.location = '/work/';
    }

    render() {
        const { error: stateError } = this.state;
        const containerStyle = {
            background: 'white',
            boxShadow: '2px 2px 10px rgba(0,0,0,.2)',
            padding: '0px',
        };

        const wrapperStyle = {
            background: '#fafafa',
        };

        let error = null;
        if (stateError) {
            error = <Alert type="danger">{stateError}</Alert>;
        }

        return (
            <div style={wrapperStyle}>
                <Container min_height style={containerStyle}>
                    <div className="row">
                        <div className="col-12">
                            <br />
                            <br />
                            {error}
                            <h1>
                                Welcome to MathAnex. Please hold while we
                                redirect you.
                            </h1>
                        </div>
                    </div>
                </Container>
            </div>
        );
    }
}
